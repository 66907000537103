import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/seo"
import componentStyles from "./mosaic.module.scss"
import MosaicIllustration from "../../assets/images/projects/mosaic.png"
import { findIndex, projectsList } from "../../hooks"
import {
  getBigThumbnailByLink,
  useBigProjectsCardsThumbnails,
} from "../../hooks/useBigPreviewProjectsCardsThumbnails"
import projectsStyles from "../projects.module.scss"
import SmallProjectCard from "../../components/SmallProjectCard/SmallProjectCard"
import LearnMoreSection from "../../components/LearnMoreSection/LearnMoreSection"

const tilesItems = [
  "projects.mosaic.technology-background.list.first-text",
  "projects.mosaic.technology-background.list.second-text",
  "projects.mosaic.technology-background.list.third-text",
  "projects.mosaic.technology-background.list.forth-text",
]

const Mosaic: React.FC<PageProps> = ({ location }) => {
  const intl = useIntl()
  const projects = projectsList
  const projectsThumbnails = useBigProjectsCardsThumbnails()

  const bannerClasses = classNames(componentStyles.banner)
  const bannerContainerClasses = classNames(
    componentStyles.bannerContainer,
    "container",
    "grid-2"
  )
  const challengeClasses = classNames(
    componentStyles.challenge,
    "container"
  )
  const challengeTitleClasses = classNames(
    componentStyles.challengeTitle,
    "large-section-title",
    "section-title-h2"
  )
  const solutionDescriptionClasses = classNames(
    componentStyles.solutionDescription,
    "container"
  )
  const solutionDescriptionTitleClasses = classNames(
    componentStyles.solutionDescriptionTitle,
    "large-section-title",
    "section-title-h2"
  )

  const technologyBackgroundClasses = classNames(
    componentStyles.technologyBackground,
    "container"
  )
  const technologyBackgroundTitleClasses = classNames(
    componentStyles.technologyBackgroundTitle,
    "large-section-title",
    "section-title-h2"
  )
  const technologyBackgroundTextClasses = classNames(
    componentStyles.technologyBackgroundText
  )
  const tilesListClasses = classNames(
    componentStyles.technologyBackgroundTilesList,
    "grid-4"
  )

  const resultsClasses = classNames(componentStyles.results, "container")
  const resultsTitleClasses = classNames(
    componentStyles.resultsTitle,
    "large-section-title",
    "section-title-h2"
  )
  const projectsTitleClasses = classNames("large-section-title")
  const blockPreview = classNames(projectsStyles.blockPreview, "container")
  const pathname = location.pathname
  const projectUrl = pathname.split("/")[3]
  const projectIndex = findIndex(projectUrl)

  return (
    <Layout location={location}>
      <SEO
        language={intl.locale}
        description={intl.formatMessage({
          id: "projects.mosaic.seo.description",
        })}
        title={intl.formatMessage({ id: "projects.mosaic.seo.title" })}
        pathname={location.pathname}
      />
      <section className={bannerClasses}>
        <div className={bannerContainerClasses}>
          <div className={componentStyles.bannerWrapper}>
            <h1 className={componentStyles.bannerTitle}>
              {intl.formatMessage({
                id: "projects.mosaic.banner.title",
              })}
            </h1>
          </div>
          <div className={componentStyles.bannerImage}>
            <img
              className={componentStyles.bannerImageMainImage}
              src={MosaicIllustration}
              alt="Illustration with white abstract figure on orange background"
              width="677"
              height="473"
            />
          </div>
        </div>
      </section>
      <section className="container">
        <div className={componentStyles.clientCompany}>
          <h2 className="large-section-title section-title-h2">
            {intl.formatMessage({
              id: "projects.mosaic.banner.client-company",
            })}
          </h2>
          <div className={componentStyles.sectionText}>
            {intl.formatMessage({
              id: "projects.mosaic.banner.client-name",
            })}
          </div>
        </div>
      </section>
      <section className={challengeClasses}>
        <div className={componentStyles.challengeTextBlock}>
          <h2 className={challengeTitleClasses}>
            {intl.formatMessage({
              id: "projects.mosaic.challenge.title",
            })}
          </h2>
          <p className={componentStyles.challengeText}>
            {intl.formatMessage({
              id: "projects.mosaic.challenge.paragraph-1",
            })}
          </p>
        </div>
      </section>
      <section className={solutionDescriptionClasses}>
        <div className={componentStyles.solutionDescriptionTextBlock}>
          <h2 className={solutionDescriptionTitleClasses}>
            {intl.formatMessage({
              id: "projects.mosaic.solution-description.title",
            })}
          </h2>
          <p className={componentStyles.solutionDescriptionText}>
            {intl.formatMessage({
              id: "projects.mosaic.solution-description.text",
            })}
          </p>
        </div>
      </section>
      <section className={technologyBackgroundClasses}>
        <h2 className={technologyBackgroundTitleClasses}>
          {intl.formatMessage({
            id: "projects.mosaic.technology-background.title",
          })}
        </h2>
        <p className={technologyBackgroundTextClasses}>
          {intl.formatMessage({
            id: "projects.mosaic.technology-background.paragraph-1",
          })}
        </p>
        <ul className={tilesListClasses}>
          {tilesItems.map(textPath => (
            <li
              key={textPath}
              className={componentStyles.technologyBackgroundTilesItem}
            >
              {intl.formatMessage({
                id: textPath,
              })}
            </li>
          ))}
        </ul>
        <p className={technologyBackgroundTextClasses}>
          {intl.formatMessage({
            id: "projects.mosaic.technology-background.paragraph-2",
          })}
        </p>
      </section>
      <section className={resultsClasses}>
        <h2 className={resultsTitleClasses}>
          {intl.formatMessage({
            id: "projects.mosaic.results.title",
          })}
        </h2>
        <p className={componentStyles.resultsText}>
          {intl.formatMessage({
            id: "projects.mosaic.results.paragraph-1",
          })}
        </p>
      </section>
      <section className={blockPreview}>
        <h2 className={projectsTitleClasses}>
          {intl.formatMessage({ id: "index.projects.next-project" })}
        </h2>
        {projects
          .filter(project => project.link !== projectUrl)
          .slice(projectIndex, projectIndex + 1)
          .map(({ title, color, link, banner }) => {
            const thumbnail = getBigThumbnailByLink(link, projectsThumbnails)
            return (
              <SmallProjectCard
                key={title}
                title={title}
                thumbnail={thumbnail}
                link={link}
                color={color}
                banner={banner}
                backgroundSize="cover"
                rightBlockStyle="none"
                buttonColor="white"
              />
            )
          })}
      </section>
      <LearnMoreSection />
    </Layout>
  )
}
export default Mosaic
